import React, { FC, useContext } from 'react';

import ScreenRecognitionContext from 'utils/context/screenRecognitionContext';
import UmbracoImage from 'common/Image/UmbracoImage';

import { TableProps } from './models';

import './Table.scss';

const Table: FC<TableProps> = ({ title, columns }) => {
  const { isMobile } = useContext(ScreenRecognitionContext);

  return (
    <section className="comparison-table">
      <h2 className="comparison-table__title">{title}</h2>

      {columns?.length ? (
        <div className="comparison-table__columns">
          {columns.map(({ subTitle, subTitleSvg, column }) => (
            <div className="comparison-table__column">
              <div className="comparison-table__sub-title">
                {subTitleSvg.fluid ? (
                  <UmbracoImage
                    image={subTitleSvg}
                    alt={subTitle}
                    className="comparison-table__sub-title-image"
                  />
                ) : (
                  <img
                    src={subTitleSvg.fallbackUrl}
                    alt={subTitle}
                    width="260"
                    height="48"
                    loading="lazy"
                    className="comparison-table__sub-title-image"
                  />
                )}
              </div>

              {column?.length ? (
                <div className="comparison-table__sub-columns">
                  {column.map(({ columnTitle, items }, index) => (
                    <div className="comparison-table__sub-column">
                      <h3 className="comparison-table__sub-column-title" key={columnTitle}>
                        {columnTitle}
                      </h3>

                      {items.map(({ advantage, iconAltText, icon }) => (
                        <div className="comparison-table__item">
                          {!isMobile && index !== 0 ? null : (
                            <div className="comparison-table__item-icon">
                              {subTitleSvg.fluid ? (
                                <UmbracoImage image={icon} alt={iconAltText} />
                              ) : (
                                <img
                                  src={icon.fallbackUrl}
                                  alt={iconAltText}
                                  width="40"
                                  height="40"
                                  loading="lazy"
                                />
                              )}
                            </div>
                          )}

                          <p className="comparison-table__item-text">{advantage}</p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
    </section>
  );
};

export default Table;
