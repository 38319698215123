import React, { FC, useContext } from 'react';
import classNames from 'classnames';

import ScreenRecognitionContext from 'utils/context/screenRecognitionContext';
import UmbracoImage from 'common/Image/UmbracoImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { ComparisonContentBannerProps } from './models';

import './ComparisonContentBanner.scss';

const ComparisonContentBanner: FC<ComparisonContentBannerProps> = ({
  title,
  richText,
  image,
  imageMobile,
  imageAlt,
  packshot,
  packshotAltText,
  textSvg,
  altTextSvg,
  sectionStyleDark,
}) => {
  const { isMobile } = useContext(ScreenRecognitionContext);

  return (
    <section
      className={classNames('comparison-content-banner', {
        'comparison-content-banner--gray-style': sectionStyleDark,
      })}
    >
      <h2 className="sr-only">{title}</h2>
      {isMobile && image ? (
        <UmbracoImage
          lazyLoad
          image={imageMobile}
          alt={imageAlt}
          className="comparison-content-banner__background"
        />
      ) : (
        <UmbracoImage
          lazyLoad
          image={image}
          alt={imageAlt}
          className="comparison-content-banner__background"
        />
      )}

      {textSvg && !isMobile ? (
        <div className="comparison-content-banner__label">
          {textSvg.fluid ? (
            <UmbracoImage
              image={textSvg}
              alt={altTextSvg}
              className="comparison-content-banner__label-image"
            />
          ) : (
            <img
              src={textSvg.fallbackUrl}
              alt={altTextSvg}
              width="181"
              height="33"
              loading="lazy"
              className="comparison-content-banner__label-image"
            />
          )}
        </div>
      ) : null}

      <div className="comparison-content-banner__content" data-testid="comparison-packshot">
        {packshot ? (
          <UmbracoImage
            lazyLoad
            image={packshot}
            objectFit="contain"
            alt={packshotAltText}
            className="comparison-content-banner__packshot"
          />
        ) : null}
        {richText ? (
          <DangerouslySetInnerHtml html={richText} className="comparison-content-banner__text" />
        ) : null}
      </div>
    </section>
  );
};

export default ComparisonContentBanner;
