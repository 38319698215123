import React, { FC } from 'react';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import { IBodyStructureElement, IPropsBodyRenderer } from './model';

const BodyRenderer: FC<IPropsBodyRenderer> = ({
  bodyData,
  bodyStructure,
}): React.ReactElement | null => {
  if (bodyData?.length === 0) return null;

  return (
    <>
      {bodyData?.map((bodyItem: IBodyStructureElement, index: number) => {
        const keyId = `${bodyItem.structure}_${index}`;

        return bodyStructure[bodyItem.structure] ? (
          <ConditionalWrapper key={keyId}>
            {bodyStructure[bodyItem.structure](
              {
                properties: { ...bodyItem.properties },
                structure: bodyItem.structure,
              },
              keyId
            )}
          </ConditionalWrapper>
        ) : null;
      })}
    </>
  );
};

export default BodyRenderer;
