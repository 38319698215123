import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import Banner from 'common/Banner';
import Tabs from 'common/Tabs';
import BodyRenderer from 'common/BodyRenderer';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import ComparisonContentBanner from 'common/ComparisonContentBanner';
import RelatedProducts from 'components/RelatedProducts';
import Table from 'common/Table';
import { toBoolean } from 'utils/stringUtils/stringUtils';

import { ComparisonTemplateTypes } from '../../@types/content/comparisonPage';

import './comparisonPage.scss';

const ComparisonTemplatePage: FC<ComparisonTemplateTypes.ComparisonTemplateProps> = ({
  data: {
    allUmbracoComparisonTemplate: { nodes: umbracoComparisonTemplate },
    allSalsifyProducts: { nodes: salsifyProductsNodes },
  },
}) => {
  const {
    langProps: { urls, lang },
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, ogImage },
    comparisonBanner,
    links,
    comparisonBlocks,
    table,
  } = umbracoComparisonTemplate[0];

  const relatedProductsList = (relatedProductSku) => {
    const skuList = relatedProductSku.map(({ value }) => value);

    return salsifyProductsNodes.filter((product) => skuList.includes(product.sku));
  };

  const comparisonBlocksContent = {
    'Rich Text': ({ properties }, keyId) => (
      <DangerouslySetInnerHtml
        key={keyId}
        html={properties.richText}
        className="comparison-page__text"
      />
    ),
    'Related Products': ({ properties }, keyId) => (
      <RelatedProducts
        key={keyId}
        relatedProductsTitle={properties.relatedProductsTitle}
        relatedProductsNodes={relatedProductsList(properties.relatedProductSku)}
      />
    ),
    'Comparison Content Banner': ({ properties }, keyId) => (
      <ComparisonContentBanner
        key={keyId}
        title={properties.title}
        richText={properties.richText}
        image={properties.image}
        imageMobile={properties.imageMobile}
        imageAlt={properties.imageAlt}
        packshot={properties.packshot}
        packshotAltText={properties.packshotAltText}
        textSvg={properties.textSvg}
        altTextSvg={properties.altTextSvg}
        sectionStyleDark={properties.sectionStyleDark}
      />
    ),
  };

  return (
    <Layout langProps={{ urls, lang }}>
      <Seo
        title={seoMetaTitle}
        description={seoMetaDescription}
        keywords={seoMetaKeywords}
        externalHreflangs={seoExternalHreflangs}
        ogImage={ogImage}
        lang={lang}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      {comparisonBanner ? (
        <Banner
          content={comparisonBanner.content}
          bannerVariant={comparisonBanner.bannerVariant}
          button={comparisonBanner.button}
          imageAsBackground={comparisonBanner.imageAsBackground}
          imageAsBackgroundAlt={comparisonBanner.imageAsBackgroundAlt}
          isWithWrapper={toBoolean(comparisonBanner.withWrapper)}
          lazyload={false}
        />
      ) : null}
      {links?.length ? (
        <Tabs
          seoTitle={links[0].seoTitle}
          linkList={links[0].linkList}
          sectionStyleDark={links[0].sectionStyleDark}
        />
      ) : null}
      {comparisonBlocks?.length ? (
        <div className="comparison-page">
          <BodyRenderer bodyData={comparisonBlocks} bodyStructure={comparisonBlocksContent} />
        </div>
      ) : null}
      {table?.length ? <Table title={table[0].title} columns={table[0].columns} /> : null}
    </Layout>
  );
};

export const query = graphql`
  query ComparisonTemplateQuery($pageId: String, $lang: String) {
    allUmbracoComparisonTemplate(
      filter: { id: { eq: $pageId }, langProps: { lang: { eq: $lang } } }
    ) {
      nodes {
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
          ogImage
        }
        title
        comparisonBanner {
          content
          bannerVariant
          withWrapper
          button {
            ariaLabel
            text
            withReverse
            link {
              url
            }
          }
          imageAsBackground {
            ...umbracoImage
          }
          imageAsBackgroundAlt
        }
        links {
          seoTitle
          linkList {
            linkAriaLabel
            link {
              name
              queryString
              url
            }
          }
          sectionStyleDark
        }
        table {
          title
          columns {
            subTitle
            subTitleSvg {
              ...umbracoImage
            }
            column {
              columnTitle
              items {
                advantage
                icon {
                  ...umbracoImage
                }
                iconAltText
              }
            }
          }
        }
        comparisonBlocks {
          properties {
            title
            richText
            relatedProductsTitle
            relatedProductsLearnMore
            relatedProductSku {
              value
              hasFocus
            }
            image {
              ...umbracoImage
            }
            imageMobile {
              ...umbracoImage
            }
            imageAlt
            packshot {
              ...umbracoImage
            }
            packshotAltText
            textSvg {
              ...umbracoImage
            }
            altTextSvg
            sectionStyleDark
          }
          structure
        }
      }
    }
    allSalsifyProducts(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        localProductImage {
          childImageSharp {
            fluid(quality: 10) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        productImage
        relatedProducts {
          relatedProductsLearnMore
        }
        variantProductCards {
          title
          image
          size
          titleShort
          titleWithSize
          url
          ean
        }
        productTitle
        productDescriptionLong
        productTitleWithSize
        url
        sku
      }
    }
  }
`;

export default ComparisonTemplatePage;
