import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { TabsType } from './models';

import './Tabs.scss';

const Tabs: FC<TabsType> = ({ seoTitle, linkList, sectionStyleDark }) => (
  <section
    className={classNames('tabs', {
      'dark--style': sectionStyleDark,
    })}
  >
    <h2 className="tabs__title sr-only">{seoTitle}</h2>

    {linkList?.length ? (
      <div className="tabs__container">
        <ul className="tabs__list">
          {linkList.map((linkItem) => (
            <li className="tabs__list-item" key={linkItem.link.name}>
              <Link
                aria-label={linkItem.linkAriaLabel}
                to={linkItem.link.url}
                className="tabs__link"
                activeClassName="tabs__link--active"
                role="link"
                partiallyActive
              >
                {linkItem.link.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    ) : null}
  </section>
);

export default Tabs;
