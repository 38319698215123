import { FC } from 'react';

interface ConditionalWrapProps {
  wrapper?: (children: JSX.Element) => JSX.Element;
  children: JSX.Element;
}

const ConditionalWrapper: FC<ConditionalWrapProps> = ({ children }) => children;

export default ConditionalWrapper;
